export default ({ request }) => ({
  /**
   * @description 测试接口
   * @param {Object} data 携带的信息
   */
  // USER_INFO(data = {}) {
  //   // 接口请求
  //   return request({
  //     url: '/woman/index',
  //     method: 'get',
  //     params: data
  //   })
  // },

  /**
   * 账号登录
   * @param {Object} data
   */
  Alogin(data) {
    return request({
      url: '/psl/user/login',
      method: 'post',
      data,
    });
  },
  // 登录埋点数据接口
  userLog(data) {
    return request({
      url: '/psl/user/userLog',
      method: 'post',
      data,
    });
  },
  /**
   * 获取验证码
   * @param { Object } data
   */
  getCode(data) {
    return request({
      url: '/psl/user/code/login',
      method: 'post',
      data,
    });
  },
  /**
   * 验证码登录
   * @param { Object } data
   */
  Blogin(data) {
    return request({
      url: '/psl/user/login/code',
      method: 'post',
      data,
    });
  },
  /**
   * 个人用户注册
   * @param { Object } data
   */
  PrivateRegister(data) {
    return request({
      url: '/psl/user/regist',
      method: 'post',
      data,
    });
  },
  /**
   * 注册发送验证码(个人注册公司注册通用)
   * @param { Object } data
   */
  sendCode(data) {
    return request({
      url: '/psl/user/code/regist',
      method: 'post',
      data,
    });
  },

  /**
   * 公司用户注册
   * @param { Object }
   */
  CompanyRegister(data) {
    return request({
      url: '/psl/team/create',
      method: 'post',
      data,
    });
  },
  /**
   * 获取省份
   */
  Province() {
    return request({
      url: '/psl/provinces/get',
      method: 'post',
    });
  },

  /**
   *忘记密码 发送验证码
   */
  Forget(data) {
    return request({
      url: '/psl/user/code/password/forget',
      method: 'post',
      data,
    });
  },
  /**
   * 忘记密码 重置密码
   * @param { Object } data
   */
  reSet(data) {
    return request({
      url: '/psl/user/password/forget',
      method: 'post',
      data,
    });
  },
  /**
   * 获取个人信息
   * @param { header } token
   */
  information(data) {
    return request({
      url: '/psl/user/info/get',
      method: 'post',
      headers: data,
    });
  },
});
